import React from "react"
import BoxCollection from "../containers/BoxCollection"
import Box from "../components/Box"
import boxColors from "../styles/box-colors.module.scss"
import Layout from "../containers/Layout"

export default () => (
  <Layout>
    <BoxCollection colorClassName={boxColors.red}>
      <Box link="/slides" label="Slides" />
      <Box link="/generative-art" label="Generative Art" />
      <Box link="/infovis" label="Arabismen" />
    </BoxCollection>
    <BoxCollection title="Miscellaneous" colorClassName={boxColors.yellow}>
      <Box link="/hex-clock" label="hex-clock" />
      <Box link="/xkcd-clock" label="XKCD-clock" />
      {/*<Box link="/kanguru" label="Auszüge aus den Känguru-Chroniken" />*/}
      <Box link="/tictactoe" label="Pro TicTacToe" />
      <Box link="/brownies" label="Brownies-Rezept" />
    </BoxCollection>
    <BoxCollection title="Exploriments" colorClassName={boxColors.blue}>
      <Box link="/bubbles" label="Bubbles" />
      <Box link="/breathe" label="Breathe" />
      <Box
        url="https://jsfiddle.net/user/leonard_follner/fiddles/"
        label="JSFiddle"
      />
    </BoxCollection>
    <BoxCollection title="Contact" colorClassName={boxColors.green}>
      <Box url="mailto:leonard.follner@icloud.com" label="Mail" />
      <Box link="/pubkey.asc" label="PGP" />
      <Box url="https://github.com/LeonardFollner/" label="GitHub" />
    </BoxCollection>
    <BoxCollection title="Rechtliches" colorClassName={boxColors.grey}>
      {/*<Box link="/impressum.html" label="Impressum" />*/}
      <Box link="/datenschutz" label="Datenschutzerklärung" />
    </BoxCollection>
  </Layout>
)
