import React from "react"
import styles from "../styles/box-collection.module.scss"

const renderBoxesAndPassColorProp = (children, colorClassName) => {
  if (Array.isArray(children)) {
    return children.map(child => React.cloneElement(child, { colorClassName }));
  } else {
    return React.cloneElement(children, { colorClassName });
  }
}

export default ({ title, children, colorClassName }) => (
  <section>
    <h2>{title}</h2>
    <div className={styles.container}>
      {renderBoxesAndPassColorProp(children, colorClassName)}
    </div>
  </section>
)