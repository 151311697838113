import React, { PureComponent } from "react"
import styles from "../styles/box.module.scss"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

export default class Box extends PureComponent {
  render() {
    const { link, url, label, colorClassName } = this.props
    const className = `${styles.shadow} ${styles.curtain} ${colorClassName}`

    const children = (
      <div className={styles.box__content}>
        <h3>{label}</h3>
        <span className={styles.arrow}>
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      </div>
    )

    return (
      <div className={styles.box}>
        {link ? (
          <Link className={className} to={link}>
            {children}
          </Link>
        ) : (
          <a className={className} href={url}>
            {children}
          </a>
        )}
      </div>
    )
  }
}
