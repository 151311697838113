import React, { Fragment } from "react"
import "../styles/global.scss"
import styles from "../styles/layout.module.scss"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

export default ({ children, title }) => {
  const headline = title ? title : "leonardfollner.de"
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{headline}</title>
      </Helmet>
      <div className={styles.layout}>
        <Link to="/">
          <h1>{headline}</h1>
        </Link>
        {children}
      </div>
    </Fragment>
  )
}
